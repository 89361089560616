import { useTranslation } from "react-i18next";

export interface SocialMedia {
  className: string;
  url: string;
}

export interface FooterLink {
  title: string;
  url: string;
}
export interface FooterLinkGroup {
  links: FooterLink[];
}
export interface FooterProps {
  socialMedia?: SocialMedia[];
  linkGroups?: FooterLinkGroup[];
}

export function Footer({ socialMedia, linkGroups }: FooterProps) {
  const {t} = useTranslation()
  return (
    <footer>
      <div className="container container-large">
        <div className="row">
          <div className="col-12">
            <div className="top-line"></div>
            <nav className="footer">
              <div className="row">
                {linkGroups?.map((linkgroup) => {
                  return (
                    <div className="col-lg-3 col-md-6">
                      <ul>
                        {linkgroup.links.map((link) => {
                          return (
                            <li>
                              <a href={link.url}>{link.title}</a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </nav>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="socialmedia">
                  {socialMedia?.map((social) => {
                    return (
                      <a
                        href={social.url}
                        target="_blank"
                        className={social.className}
                        rel="noreferrer"
                        key={social.className}
                      ></a>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="copyright">
                  <p className="link-paragraph">
                    <a className="link-red" href="https://www.outlast.com/">www.outlast.com</a>
                    <a href="https://www.outlast.com/impressum">{t("MAIN.IMPRESSUM")}</a>
                    <a href="https://www.outlast.com/datenschutz">{t("MAIN.DATENSCHUTZ")}</a>
                    <p>2022® Outlast Technologies GmbH{" "}</p>
                    <a href="/">
                      <i className="icon-webfont_outlast_logo_sw" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

import { asset } from "../../util/asset";
import { Section } from "../layout/section";
import { Image } from "../module-content/image";
import { ImageText } from "../module-content/image-text";
import { Paragraph } from "../module-content/paragraph";
import {useTranslation} from "react-i18next";

const INTRO_TEXT_A = "VORTEILE.INTRO_TEXT_A";
const INTRO_TEXT_B = "VORTEILE.INTRO_TEXT_B";
const IMAGE_TEXT_1 = "VORTEILE.IMAGE_TEXT_1";
const IMAGE_TEXT_2 = "VORTEILE.IMAGE_TEXT_2";
// const TEXT_3 = "VORTEILE.TEXT_3";
const TIPP_TEXT = "VORTEILE.TIPP_TEXT";

export interface VorteileProps {
  variant: "a" | "b";
}

function Vorteile({ variant = "a" }: VorteileProps) {
  const {t} = useTranslation();
  let introText = INTRO_TEXT_A;

  switch (variant) {
    case "a":
      introText = INTRO_TEXT_A;
      break;
    case "b":
      introText = INTRO_TEXT_B;
      break;
  }

  return (
    <>
      <Paragraph className="size-small" text={introText}>
        <h4>{t("VORTEILE.TITLE")}</h4>
      </Paragraph>
      {variant === "a" && (
        <ImageText
          border
          className="size-small"
          imageUrl={asset("img/out_ComfortZone_Sportlerin.jpg")}
          text={IMAGE_TEXT_1}
        ></ImageText>
      )}
      {variant === "b" && <Paragraph className="no-padding-top" text={IMAGE_TEXT_1}></Paragraph>}
      <ImageText
        border
        className="gray "
        imageUrl={asset("img/skifahrer.webp")}
        text={IMAGE_TEXT_2}
      >
        <h4>
          {t("VORTEILE.THERMO_Q")}
        </h4>
        <div className="top-line"></div>
        <div className="bottom-line"></div>
      </ImageText>
      <Section>
        <h4>{t("VORTEILE.SWEAT")}</h4>
        <p>
        {t("VORTEILE.TEXTBODY_1")}
        </p>
        {variant === "b" && (
          <img
            src={asset("img/Outlast_Schulung_soldiers.jpg")}
            alt="Maschierende Soldaten"
            style={{padding: "30px 0px"}}
          ></img>
        )}
      {/* </Section>
      <Section className="size-small"> */}
        <p>
        <b>
        {t("VORTEILE.RESULT")}
        </b>
        {t("VORTEILE.RESULT_2")}
        </p>
      {/* </Section>
      <Section className="size-small"> */}
        <p>
        {t("VORTEILE.MATERIAL")}
        </p>
      {/* </Section>
      <Section className="size-small"> */}
        <ul className="module-list">
          <li>{t("VORTEILE.LISTITEM_1")}</li>
          <li>{t("VORTEILE.LISTITEM_2")}</li>
          <li>{t("VORTEILE.LISTITEM_3")}</li>
          <li>{t("VORTEILE.LISTITEM_4")}</li>
          <li>{t("VORTEILE.LISTITEM_5")}</li>
          <li>{t("VORTEILE.LISTITEM_6")}</li>
          <li>{t("VORTEILE.LISTITEM_7")}</li>
          <li>{t("VORTEILE.LISTITEM_8")}</li>
        </ul>
      </Section>
      <Paragraph className="blue">
        <p>
          <b>{t("MAIN.SALES_HINT")} </b> {t(TIPP_TEXT)}
        </p>
        <div className="top-line"></div>
      </Paragraph>
    </>
  );
}
export const VorteileA = () => <Vorteile variant="a" />;
export const VorteileB = () => <Vorteile variant="b" />;

const INTRO_TEXT_C = "VORTEILE.INTRO_TEXT_C"
const INTRO_TEXT_2_C = "VORTEILE.INTRO_TEXT_2_C"
const INFO_TEXT_C = "VORTEILE.INFO_TEXT_C"
export const VorteileC = () => {
  const {t} = useTranslation();
  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT_C}>
        <h4>{t("VORTEILE.TITLE")}</h4>
      </Paragraph>
      <Paragraph className="size-small" text={INTRO_TEXT_2_C}></Paragraph>
      <Image
        info={INFO_TEXT_C}
        imageUrl={asset("img/Outlast_Schulung_statistic.png")}
        className="no-padding-top"
      ></Image>
      <Section className="gray">
        <div className="top-line"></div>
        <h4>{t("VORTEILE.SLEEP")}</h4>
        <p>
        {t("VORTEILE.SLEEP_BODY")}
        </p>
        <ul className="module-list">
          <li>
            <b>{t("VORTEILE.LISTITEM_C_1")}</b> {t("VORTEILE.LISTITEM_C_1_BODY")}
          </li>
          <li>
            <b>{t("VORTEILE.LISTITEM_C_6")}</b> {t("VORTEILE.LISTITEM_C_6_BODY")}
          </li>
          <li>
            <b>{t("VORTEILE.LISTITEM_C_2")}</b> {t("VORTEILE.LISTITEM_C_2_BODY")}
          </li>
          <li>
            <b>{t("VORTEILE.LISTITEM_C_3")}</b> {t("VORTEILE.LISTITEM_C_3_BODY")}
          </li>
          <li>
            <b>{t("VORTEILE.LISTITEM_C_4")}</b> {t("VORTEILE.LISTITEM_C_4_BODY")}
          </li>
          <li>
            <b>{t("VORTEILE.LISTITEM_C_5")}</b> {t("VORTEILE.LISTITEM_C_5_BODY")}
          </li>
        </ul>
        <div
          style={{ marginBottom: "4rem", marginTop: "4rem" }}
          className="col-12"
        >
          <img src={asset("img/out_header_bedding_1100x500.jpg")} alt=""></img>
        </div>

        <h4>{t("VORTEILE.STUDIES")}</h4>
        <p>
        {t("VORTEILE.STUDIE_BODY")}
        </p>
        <div style={{ marginTop: "2rem" }} className="col-12">
          <img
            src={asset("img/tabelle_risiko_out_statistic_sleep.png")}
            alt=""
          ></img>
        </div>
        <div className="bottom-line"></div>
      </Section>
      <Section>
        <p>
          {t("VORTEILE.MATERIAL_C")}
        </p>
        <ul className="module-list">
          <li>{t("VORTEILE.LISTITEM_C_2_1")}</li>
          <li>{t("VORTEILE.LISTITEM_C_2_2")}</li>
          <li>{t("VORTEILE.LISTITEM_C_2_3")}</li>
          <li>{t("VORTEILE.LISTITEM_C_2_4")}</li>
          <li>{t("VORTEILE.LISTITEM_C_2_5")}</li>
          <li>{t("VORTEILE.LISTITEM_C_2_6")}</li>
        </ul>
      </Section>
      <Paragraph className="blue">
        <p>
          <b>{t("MAIN.SALES_HINT")}</b>
          {t("VORTEILE.TIPP_TEXT_C")}
        </p>

        <div className="top-line"></div>
      </Paragraph>
    </>
  );
};

const INTRO_TEXT_D ="VORTEILE.INTRO_TEXT_D";
// const TIPP_TEXT_D ="VORTEILE.TITLE";
export const VorteileD = () => {
  const {t} = useTranslation();
  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT_D}>
        <h4>{t("VORTEILE.TITLE")}</h4>
      </Paragraph>
      <Section className="gray">
        <img
          src={asset(
            "img/2021-09-13_Outlast_TOBIAS-FROEHNER-PHOTOGRAPHY_schuhe.jpg"
          )}
          alt="Schuhe mit Outlast® Thermo-Technologie"
        ></img>
        <p style={{ marginTop: "1rem" }}>
          {t("VORTEILE.TEXT_D")}
        </p>
        <ul className="module-list">
          <li>{t("VORTEILE.LISTITEM_D_1")}</li>
          <li>{t("VORTEILE.LISTITEM_D_2")}</li>
          <li>{t("VORTEILE.LISTITEM_D_3")}</li>
          <li>{t("VORTEILE.LISTITEM_D_4")}</li>
          <li>{t("VORTEILE.LISTITEM_D_5")}</li>
        </ul>
        <div className="top-line"></div>
      </Section>
      <Paragraph className="blue">
        <p>
          <b>{t("MAIN.SALES_HINT")}</b> {t("VORTEILE.TIPP_TEXT_D")}
        </p>
        <div className="top-line"></div>
      </Paragraph>
    </>
  );
};

import React, { useCallback, useState } from "react";
import {
  NAME_LOCAL_STORAGE_KEY as USER_LOCAL_STORAGE_KEY,
  USE_LOCAL_STORAGE,
} from "../../constants/settings";

export interface QuizContextValues {
  firstName: string;
  lastName: string;
  firm: string;
  email: string;
  mailSent: string;
}
export interface QuizContextType extends QuizContextValues {
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setFirm: (firm: string) => void;
  setEmail: (email: string) => void;
  setMailSent: (email: string) => void;
}

export const QuizContext = React.createContext<QuizContextType>({
  firstName: "",
  lastName: "",
  firm: "",
  email: "",
  mailSent: "",
  setFirstName: () => {},
  setLastName: () => {},
  setFirm: () => {},
  setEmail: () => {},
  setMailSent: () => {},
});

export interface QuizContextProviderProps {
  children?: React.ReactNode;
}
function saveUserData(values: QuizContextValues) {
  if (USE_LOCAL_STORAGE) {
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(values));
  }
}

export const getUserData = (): QuizContextValues | undefined => {
  if (USE_LOCAL_STORAGE) {
    const userData = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
    try {
      if (userData) {
        return JSON.parse(userData);
      }
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

export function QuizContextProvider({ children }: QuizContextProviderProps) {
  const [firstName, setFirstNameState] = useState("");
  const [lastName, setLastNameState] = useState("");
  const [firm, setFirmState] = useState("");
  const [email, setEmailState] = useState("");
  const [mailSent, setMailSentState] = useState("");

  const setFirstName = useCallback(
    (firstName: string) => {
      setFirstNameState(firstName);
      saveUserData({ firstName, lastName, firm, email, mailSent });
    },
    [firm, lastName, email, mailSent]
  );
  const setLastName = useCallback(
    (lastName: string) => {
      setLastNameState(lastName);
      saveUserData({ firstName, lastName, firm, email, mailSent });
    },
    [firm, firstName, email, mailSent]
  );
  const setFirm = useCallback(
    (firm: string) => {
      setFirmState(firm);
      saveUserData({ firstName, lastName, firm, email, mailSent });
    },
    [firstName, lastName, email, mailSent]
  );
  const setEmail = useCallback(
    (email: string) => {
      setEmailState(email);
      saveUserData({ firstName, lastName, firm, email, mailSent });
    },
    [firm, lastName, firstName, mailSent]
  );
  const setMailSent = useCallback(
    (mailSent: string) => {
      setMailSentState(mailSent);
      saveUserData({ firstName, lastName, firm, email, mailSent });
    },
    [firm, lastName, firstName, email]
  );

  const value = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    firm,
    setFirm,
    email,
    setEmail,
    mailSent,
    setMailSent,
  };
  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
}

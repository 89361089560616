import { asset } from "../../util/asset";
import { Section } from "../layout/section";
import { Image } from "../module-content/image";
import { ImageText } from "../module-content/image-text";
import { Paragraph } from "../module-content/paragraph";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const INTRO_TEXT =
  "FUNKTIONSWEISE.TEXT_1"
const IMAGE_TEXT_1 =
  "FUNKTIONSWEISE.TEXT_2"
const IMAGE_TEXT_2 =
  "FUNKTIONSWEISE.TEXT_3"
const TEXT_1 =
  "FUNKTIONSWEISE.TEXT_4"
const IMAGE_TEXT_3 =
  "FUNKTIONSWEISE.TEXT_5"
const IMAGE_INFO_TEXT =
  "FUNKTIONSWEISE.TEXT_6"
const TIPP_TEXT =
  "FUNKTIONSWEISE.TEXT_7"

export function Funtionsweise() {
  const {t, i18n} = useTranslation()
  const imagePath = useMemo(() => {
    return `img/Outlast_Schulung_statistic_2.${i18n.language}.png`;
  },[i18n.language])

  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT}>
        <h4>{t("FUNKTIONSWEISE.HEADLINE_1")}</h4>
      </Paragraph>
      <ImageText
      className="no-padding-top"
        imageUrl={asset("img/Outlast_Schulung_yellow_flowers.jpg")}
        text={IMAGE_TEXT_1}
        height="300px"
      />
      <ImageText
        className="gray"
        imageUrl={asset("img/Outlast_Schulung_bunte_kugeln.jpg")}
        text={IMAGE_TEXT_2}
      >
        <h4>{t("FUNKTIONSWEISE.HEADLINE_4")}</h4>
        <div className="bottom-line"></div>
        <div className="top-line"></div>
      </ImageText>
      <Paragraph text={TEXT_1}>
        <h4>{t("FUNKTIONSWEISE.HEADLINE_2")}</h4>
      </Paragraph>
      <ImageText
        text={IMAGE_TEXT_3}
        imageUrl={asset("img/Outlast_Schulung_astronaut_skala.jpg")}
        className="no-padding-top"
      ></ImageText>
      <Image
        info={IMAGE_INFO_TEXT}
        className="no-padding-top"
        imageUrl={asset(imagePath)}
        height="250px"
        scalable={true}
      ></Image>
      <Section className="blue size-small">
        <p>
          <b>{t("MAIN.SALES_HINT")}</b>
          {t(TIPP_TEXT)}
        </p>
        <div className="top-line"></div>
      </Section>
    </>
  );
}

const INTRO_TEXT_C =
  "FUNKTIONSWEISE.TEXT_C_1"
const TEXT_1_C =
  "FUNKTIONSWEISE.TEXT_C_2"
const TEXT_2_C =
  "FUNKTIONSWEISE.TEXT_C_3"
const IMAGE_TEXT_0_C =
  "FUNKTIONSWEISE.TEXT_C_4"
const IMAGE_TEXT_1_C =
  "FUNKTIONSWEISE.TEXT_C_5"
const IMAGE_INFO_TEXT_C =
  "FUNKTIONSWEISE.TEXT_C_6"
const IMAGE_INFO_TEXT_2_C =
  "FUNKTIONSWEISE.TEXT_C_7"
export function FuntionsweiseC() {
  const {t} = useTranslation()
  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT_C}>
        <h4>{t("FUNKTIONSWEISE.HEADLINE_1")}</h4>
      </Paragraph>
      <Image
        border
        imageUrl={asset("img/Outlast_Schulung_yellow_flowers.jpg")}
        height="550px"
        className="no-padding-top"
      ></Image>
      <ImageText
        border
        className="gray"
        imageUrl={asset("img/Outlast_Schulung_bunte_kugeln.jpg")}
        text={IMAGE_TEXT_0_C}
      >
        <h4>{t("FUNKTIONSWEISE.HEADLINE_4")}</h4>
        <div className="bottom-line"></div>
        <div className="top-line"></div>
      </ImageText>
      <Paragraph className="no-padding-bottom" text={TEXT_1_C}>
        <h4>{t("FUNKTIONSWEISE.HEADLINE_1")}</h4>
      </Paragraph>
      <Image
        className="size-small"
        height="500px"
        border
        imageUrl={asset("img/frau_2.jpg")}
      ></Image>
      <Paragraph className="no-padding-top" text={TEXT_2_C}></Paragraph>

      <ImageText
        border
        className="gray"
        height="400px"
        imageUrl={asset("img/frau_1.jpg")}
        text={IMAGE_TEXT_1_C}
        info={[IMAGE_INFO_TEXT_C, IMAGE_INFO_TEXT_2_C]}
      >
        <h4>{t("FUNKTIONSWEISE.HEADLINE_5")}</h4>
        <div className="top-line"></div>
      </ImageText>
      <Section></Section>
      <Section className="blue size-small">
        <p>
          <b>{t("MAIN.SALES_HINT")}</b>
          {t(TIPP_TEXT)}
        </p>
        <div className="top-line"></div>
      </Section>
    </>
  );
  //TODO: info text image text
}

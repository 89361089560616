export interface SectionProps {
  children?: React.ReactNode;
  className?: string;
  isLarge?: boolean;
}

export function Section({ className, children, isLarge }: SectionProps) {
  return (
    <section className={className}>
      <div className={`container ${isLarge? "container-large" : ""}`}>
        <div className="row">
          <div className="col-12">{children}</div>
        </div>
      </div>
    </section>
  );
}

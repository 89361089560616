import { Section } from "../layout/section";
import { useTranslation } from "react-i18next"

export interface ImageTextProps {
  imageUrl: string;
  alt?: string;
  text: string;
  translationNamespace?: string;
  children?: React.ReactNode;
  className?: string;
  border?: boolean;
  info?: string | string[];
  imageText?: string;
  textChildren?: React.ReactNode;
  height?: string;
  scalable?: boolean;
}
export function ImageText({
  imageUrl,
  alt,
  text,
  translationNamespace="translations",
  children,
  className,
  border = true,
  info,
  imageText,
  textChildren,
  height,
  scalable,
}: ImageTextProps) {
  const {t} = useTranslation();
  return (
    <Section className={`side-by-side image-text ${className}`}>
      {children}
      <div className="row">
        <div className="col-lg-6 image-text-image">
          <img
            className={`${border && "border"} ${scalable && "object-fit-scalable"} bg-white`}
            src={imageUrl}
            alt={alt}
            height={height}
          />
          { imageText && <p className="image-text-text">{t(imageText, {ns:translationNamespace})}</p> }
        </div>
        <div className="col-lg-6">
          {textChildren}
          <p>{t(text, {ns:translationNamespace})}</p>
        </div>
        {info && (
          <div className="col-12 image-text-info">
            {Array.isArray(info)
              ? info.map((i, index) => <p key={index}>{t(i,  {ns:translationNamespace})}</p>)
              : t(info)}
          </div>
        )}
      </div>
    </Section>
  );
}

import {
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  PAGE_TRANSITION_TIMEOUT,
  USE_LOCAL_STORAGE,
} from "../constants/settings";
import { Button } from "../components/button";
import { Section } from "../components/layout/section";
import { ModuleCertDownload } from "../components/modules/module-cert-download";
import { ProgressBar, QuestionState } from "../components/progress-bar";
import { QuestionComponent } from "../components/modules/question";
import { NamePage } from "./name-page";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import {
  getUserData,
  QuizContextValues,
} from "../components/context/quiz-context-provider";
// #region constants

// #endregion

// #region prop tpye
export interface Answer {
  text: string;
  resultText: string;
}

export interface Question {
  question: string;
  answers: Answer[];
  correctIndex: number;
}

export interface Part {
  name: string;
  questions: Question[];
  component: React.ComponentType<{}>;
}

export interface Module {
  name: string;
  imgSrc: string;
  estimatedTime: number;
  parts: Part[];
}

export interface ModuleProps {
  module: Module;
}
// #endregion

// #region reducer

export interface PartState {
  currentQuestionIndex: number;
  questionState: QuestionState[];
}

export interface ModuleState {
  name: string;
  parts: PartState[];
  currentPartIndex: number;
  completed: boolean;
  // if they are still viewing the document or the question
  atQuestion: boolean;
}

export type ModuleAction =
  | {
      type: "NEXT";
    }
  | ModuleActionAnswerQuestion
  | ModuleResetAction
  | ModuleChangeKeyAction;

export interface ModuleActionAnswerQuestion {
  type: "ANSWER_QUESTION";
  correct: boolean;
}

export type ModuleResetAction = {
  type: "RESET";
};

export type ModuleChangeKeyAction = {
  type: "CHANGE_KEY";
};

export const getUserString = (user: QuizContextValues) => {
  const str = `${user.firstName}_${user.lastName}_${user.email}_${user.firm}`;
  return str;
};
//TODO: move this to a separate file
// Gets the key for a modules state in localStorage
export const getModuleStateKey = (moduleName: string) => {
  const user = getUserData();
  if (!user) return `module/${moduleName}`.toLowerCase();
  return `${getUserString(user)}/module/${moduleName}`.toLowerCase();
};

const getInitialStateForModule = (module: Module): ModuleState => {
  const local = localStorage.getItem(getModuleStateKey(module.name));

  if (local && USE_LOCAL_STORAGE) {
    try {
      return JSON.parse(local);
    } catch (e) {
      console.error(e);
      localStorage.removeItem(getModuleStateKey(module.name));
    }
  }

  return {
    parts: module.parts.map((part) => ({
      currentQuestionIndex: 0,
      questionState: part.questions.map(() => QuestionState.TODO),
    })),
    currentPartIndex: 0,
    completed: false,
    atQuestion: false,
    name: module.name,
  };
};

const reducer: Reducer<ModuleState, ModuleAction> = (state, action) => {
  let resultingState: ModuleState = { ...state };
  switch (action.type) {
    case "NEXT":
      // Go to the questions if we are viewing a document
      if (!state.atQuestion && !state.completed) {
        resultingState.atQuestion = true;
        break;
      }
      // no more questions left in current part
      // Go to the next part
      const noQuestionsLeft =
        state.parts[state.currentPartIndex].questionState.findIndex(
          (q) => q === QuestionState.TODO
        ) === -1;

      if (noQuestionsLeft) {
        if (state.currentPartIndex === state.parts.length - 1) {
          resultingState.completed = true;
          resultingState.atQuestion = false;
          break;
        }

        resultingState = {
          ...state,
          currentPartIndex: state.currentPartIndex + 1,
          atQuestion: false,
        };
        break;
      }
      // more questions left in current part
      // Go to the next question
      resultingState = {
        ...state,
        parts: state.parts.map((part, index) => {
          if (index === state.currentPartIndex) {
            return {
              ...part,
              currentQuestionIndex: part.currentQuestionIndex + 1,
            };
          }
          return part;
        }),
      };
      break;
    case "ANSWER_QUESTION":
      resultingState = {
        ...state,
        parts: state.parts.map((part, index) => {
          if (index === state.currentPartIndex) {
            return {
              ...part,
              questionState: part.questionState.map(
                (questionState, questionIndex) => {
                  if (questionIndex === part.currentQuestionIndex) {
                    return action.correct
                      ? QuestionState.CORRECT
                      : QuestionState.WRONG;
                  }
                  return questionState;
                }
              ),
            };
          }
          return part;
        }),
      };
      break;
    case "CHANGE_KEY":
      try {
        const item = localStorage.getItem(getModuleStateKey(state.name));
        if (item) {
          resultingState = JSON.parse(item);
        } else {
          resultingState = {
            ...state,
            parts: state.parts.map((part) => ({
              currentQuestionIndex: 0,
              questionState: part.questionState.map(() => QuestionState.TODO),
            })),
            currentPartIndex: 0,
            completed: false,
            atQuestion: false,
          };
        }
      } catch (e) {
        localStorage.removeItem(getModuleStateKey(state.name));
        resultingState = {
          ...state,
          parts: state.parts.map((part) => ({
            currentQuestionIndex: 0,
            questionState: part.questionState.map(() => QuestionState.TODO),
          })),
          currentPartIndex: 0,
          completed: false,
          atQuestion: false,
        };
      }
      break;
    case "RESET":
      console.log("reset");
      resultingState = {
        ...state,
        parts: state.parts.map((part) => ({
          currentQuestionIndex: 0,
          questionState: part.questionState.map(() => QuestionState.TODO),
        })),
        currentPartIndex: 0,
        completed: false,
        atQuestion: false,
      };
      break;
  }
  if (USE_LOCAL_STORAGE) {
    localStorage.setItem(
      getModuleStateKey(state.name),
      JSON.stringify(resultingState)
    );
  }
  return resultingState;
};
// #endregion

// #region selectors
const selectCorrect = (state: ModuleState) => {
  return state.parts.reduce(
    (acc, part) =>
      acc +
      part.questionState.filter((q) => q === QuestionState.CORRECT).length,
    0
  );
};
const selectQustionProgress = (state: ModuleState) => {
  return state.parts.reduce(
    (acc, part) =>
      acc + part.questionState.filter((q) => q !== QuestionState.TODO).length,
    0
  );
};
const selectCurQuestionAnswered = (state: ModuleState) => {
  return (
    state.parts[state.currentPartIndex].questionState[
      state.parts[state.currentPartIndex].currentQuestionIndex
    ] !== QuestionState.TODO
  );
};
const selectQuestionStates = (state: ModuleState) => {
  return state.parts.reduce<QuestionState[]>((acc, part) => {
    return [...acc, ...part.questionState];
  }, []);
};
const selectOnQuestion = (state: ModuleState) => {
  return state.atQuestion;
};
const selectCompleted = (state: ModuleState) => {
  return state.completed;
};
// #endregion

export function ModulePage({ module }: ModuleProps) {
  const { t } = useTranslation();
  const totalQuestions = module.parts.reduce((acc, part) => {
    return acc + part.questions.length;
  }, 0);

  const [state, dispatch] = useReducer(
    reducer,
    getInitialStateForModule(module)
  );
  const [nameSet, setNameSet] = useState(false);

  const fadeRef = useRef<HTMLDivElement>(null);
  //Reducer state
  const onQuestion = selectOnQuestion(state);
  const completed = selectCompleted(state);

  const correct = selectCorrect(state);
  const qustionProgress = selectQustionProgress(state);
  const answered = selectCurQuestionAnswered(state);
  const questionStates = selectQuestionStates(state);
  const currentPartIndex = state.currentPartIndex;

  const currentPart = module.parts[currentPartIndex];
  const currentQuestionIndex =
    state.parts[currentPartIndex].currentQuestionIndex;
  const currentQuestion = currentPart.questions[currentQuestionIndex];

  const onCorrect = useCallback(() => {
    dispatch({ type: "ANSWER_QUESTION", correct: true });
  }, []);

  const onIncorrect = useCallback(() => {
    dispatch({ type: "ANSWER_QUESTION", correct: false });
  }, []);

  const onNext = useCallback(() => {
    dispatch({ type: "NEXT" });
  }, []);
  const onReset = useCallback(() => {
    dispatch({ type: "RESET" });
  }, []);

  useEffect(() => {
    if (!nameSet) return;
    const id = setTimeout(() => {
      if (!window.scroll) {
        window.screenTop = 0;
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      return () => clearTimeout(id);
    }, 100);
  }, [currentPartIndex, currentQuestionIndex, onQuestion, completed, nameSet]);

  useEffect(() => {
    if (nameSet) return;
  }, [nameSet]);

  if (!nameSet) {
    return (
      <NamePage
        onSubmit={() => {
          setNameSet(true);
          dispatch({ type: "CHANGE_KEY" });
        }}
      />
    );
  }

  const PartComponent = currentPart.component;

  return (
    <div>
      <Section className="blue size-small">
        <h1 style={{ marginBottom: 0, textTransform: "none" }}>
          {t(`HEADLINE.${module.name}`, { ns: "translations" })}
        </h1>
        <div className="bottom-line"></div>
      </Section>
      <div className="module-page">
        <ProgressBar
          progress={qustionProgress}
          amount={totalQuestions}
          correct={correct}
          questionResults={questionStates}
        />
        {/* I Know these scss animations are wrong. But they work so */ ""}
        <TransitionGroup component={null}>
          <CSSTransition
            key={`${currentPartIndex}_${currentQuestionIndex}_${onQuestion}`}
            classNames={nameSet && "fade"}
            nodeRef={fadeRef}
            timeout={PAGE_TRANSITION_TIMEOUT}
          >
            {completed ? (
              <div ref={fadeRef}>
                <ModuleCertDownload
                  moduleName={module.name}
                  totalQuestions={totalQuestions}
                  correctQuestions={correct}
                ></ModuleCertDownload>
              </div>
            ) : onQuestion ? (
              <div ref={fadeRef}>
                <Section>
                  <QuestionComponent
                    overrideCorrect={
                      questionStates[currentQuestionIndex] ===
                      QuestionState.CORRECT
                    }
                    question={currentQuestion}
                    onCorrect={onCorrect}
                    onIncorrect={onIncorrect}
                  ></QuestionComponent>
                  <Button right={true} onClick={onNext} disabled={!answered}>
                    {t("MAIN.BUTTON_CONTINUE", { ns: "translations" })}
                  </Button>
                </Section>
              </div>
            ) : (
              <div ref={fadeRef}>
                <Section className="size-small">
                  <h2 style={{ margin: 0 }}>
                    {currentPartIndex + 1}/{state.parts.length} Outlast® –{" "}
                    {t(currentPart.name, { ns: "questions" })}
                  </h2>
                </Section>
                <PartComponent />
                <Section>
                  <Button right={true} onClick={onNext}>
                    {t("MAIN.BUTTON_TO_QUESTIONS", { ns: "translations" })}
                  </Button>
                </Section>
              </div>
            )}
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

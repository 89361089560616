import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface HoverBoxProps {
  headline: string;
  imageSrc: string;
  imageAlt?: string;
  linkText: string;
  linkUrl: string;
  children?: React.ReactNode;
}

export function HoverBox({
  headline,
  children,
  imageSrc,
  imageAlt,
  linkText,
  linkUrl,
}: HoverBoxProps) {
  const { t } = useTranslation();
  return (
    <div className="hover-box col-lg-6 col-md-6 col-sm-12 col-12">
      <div className="box">
        <Link to={linkUrl} className="link">
          {t(headline)}
        </Link>
        <div>
          <img
            src={imageSrc}
            loading="lazy"
            alt={imageAlt}
            style={{ width: "100%" }}
          />
        </div>
        <div className="hover">
          <div className="curvedsvg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 461.1 50.1"
              preserveAspectRatio="none"
            >
              <path
                className="curved"
                d="M0,2.1v48h461.1v-48c0,0-115,22-230.6,22C145.2,24.2,41,9.6,0,2.1z"
              />
              <path
                className="curvedline"
                d="M230.4,24C140.1,24,33.3,8.1-0.2,2l0.4-2c33.5,6.1,140.1,22,230.3,22C344.6,22,459.7,0.2,460.9,0l0.4,2 C460.1,2.2,344.8,24,230.4,24z"
              />
            </svg>
          </div>
          <div className="wrapper">
            <h3>{t(`HEADLINE.${headline.split(" - ")[1]}`)}</h3>
            {children}
            <p></p>
            <a className="outline-button-link" href={linkUrl}>{t('MAIN.ZUM_MODUL')}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useRef, useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface NavItem {
  label: string;
  to: string;
  navChildren?: NavItem[];
  external?: boolean;
}

export interface NavItemProps extends NavItem {
  mobile?: boolean;
  level?: number;
  onHover?: (el: HTMLLIElement) => void;
  onLeave?: (el: HTMLLIElement) => void;
}

const NavItemComponent = React.forwardRef<HTMLLIElement, NavItemProps>(
  (
    { label, navChildren, mobile, level, onHover, onLeave, to, external },
    forwardRef
  ) => {
    const ref = useRef<HTMLLIElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
      const cur = ref.current;
      const hoverCallback = () => {
        if (onHover && cur) {
          onHover(cur);
        }
      };
      const leaveCallback = () => {
        if (onLeave && cur) {
          onLeave(cur);
        }
      };
      if (onHover && cur) {
        cur.addEventListener("mouseover", hoverCallback);
      }
      if (onLeave && cur) {
        cur.addEventListener("mouseleave", leaveCallback);
      }
      return () => {
        cur?.removeEventListener("mouseover", hoverCallback);
        cur?.removeEventListener("mouseleave", leaveCallback);
      };
    }, [onHover, onLeave]);

    return (
      <li
        ref={(node) => {
          // oh boi
          // xD
          //@ts-ignore
          ref.current = node;
          if (!forwardRef) return;
          if (typeof forwardRef === "function") {
            forwardRef(node);
          } else {
            forwardRef.current = node;
          }
        }}
      >
        {external ? (
          <a href={to}>
            {t(label)}
            {navChildren && mobile ? <i className="dropdown-link"></i> : ""}
          </a>
        ) : (
          <Link to={to}>
            {t(label)}
            {navChildren && mobile ? <i className="dropdown-link"></i> : ""}
          </Link>
        )}
        {navChildren && (
          <ul className={`level${(level ?? 1) + 1} ${mobile ? "opener" : ""}`}>
            {navChildren.map((child) => (
              <NavItemComponent
                key={child.label}
                {...child}
                level={(level ?? 1) + 1}
                mobile={mobile}
              />
            ))}
          </ul>
        )}
      </li>
    );
  }
);

export interface NavProps {
  items: NavItem[];
}

export function DesktopNav({ items }: NavProps) {
  const { i18n } = useTranslation();
  function toggleLang() {
    let nextLang = i18n.language === 'de' ? 'en' : 'de';
    i18n.changeLanguage(nextLang);
  }
  const [, setNavOpen] = useState(false);
  const currentItem = useRef<HTMLLIElement>(null);
  const [activeBarStyle, setActiveBarStyle] = useState<React.CSSProperties>({
    left: 0,
    width: 0,
  });

  const level1Ref = useRef<HTMLUListElement>(null);
  const navDesktopRef = useRef<HTMLDivElement>(null);

  const navItemHover = useCallback(
    (el: HTMLLIElement) => {
      setActiveBarStyle({
        left: el.offsetLeft + 15,
        width: el.offsetWidth,
      });
    },
    [setActiveBarStyle]
  );

  const navItemLeave = useCallback(() => {
    if (!currentItem.current) {
      setActiveBarStyle({
        left: 0,
        width: 0,
      });
      return;
    }
    navItemHover(currentItem.current);
  }, [navItemHover]);

  const location = useLocation();

  useEffect(() => {
    const level1Cur = level1Ref.current;
    const navDesktopCur = navDesktopRef.current;

    function onDesktopNavLeave(this: HTMLDivElement, e: MouseEvent) {
      if (!navDesktopCur) {
        return;
      }

      if (!this.contains(e.relatedTarget as Node)) {
        setNavOpen(false);
      }
    }

    const onLevel1Hover = () => {
      setNavOpen(true);
    };

    if (navDesktopCur) {
      navDesktopCur.addEventListener("mouseleave", onDesktopNavLeave);
    }

    if (level1Cur) {
      level1Cur.addEventListener("mouseenter", onLevel1Hover);
    }
    return () => {
      if (level1Cur) {
        level1Cur.removeEventListener("mouseenter", onLevel1Hover);
      }
      if (navDesktopCur) {
        navDesktopCur.removeEventListener("mouseleave", onDesktopNavLeave);
      }
    };
  }, [setNavOpen]);

  useEffect(() => {
    if (currentItem.current) {
      navItemHover(currentItem.current);
    }
  }, [currentItem, navItemHover]);

  return (
    <nav ref={navDesktopRef} className={`desktop`}>
      <div className="container container-large">
        <div className="row">
          <div className="col-12">
            <ul ref={level1Ref} className="level1">
              {items.map((item) => {
                const isCurrent = item.to === location.pathname;
                return (
                  <NavItemComponent
                    ref={isCurrent ? currentItem : undefined}
                    onLeave={navItemLeave}
                    key={item.label}
                    onHover={navItemHover}
                    {...item}
                  />
                );
              })}
              <div className="button-right lang-nav" onClick={toggleLang}><span className="icon-webfont_globe"></span> Change Language ({i18n.language})</div>
            </ul>
            <div className="activebar on" style={{ ...activeBarStyle }}></div>
            <Link to="/">
              <img
                className="logo"
                alt="Outlast Logo"
                src={`${process.env.PUBLIC_URL}/Outlast_Logo.svg`}
              />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export interface MobileNavProps extends NavProps {
  open?: boolean;
}
export function MobileNav({ items, open }: MobileNavProps) {
  const { i18n } = useTranslation();
  function toggleLang() {
    let nextLang = i18n.language === 'de' ? 'en' : 'de';
    i18n.changeLanguage(nextLang);
  }
  const reactNode = (
    <nav className={`mobile ${open ? "open" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="level1 loaded">
              {items.map((item) => {
                return <NavItemComponent key={item.label} {...item} mobile />;
              })}
              <li>
              <div className="button-right lang-nav mobile" onClick={toggleLang}>Change Language ({i18n.language})</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
  return ReactDOM.createPortal(
    reactNode,
    document.getElementById("root") as HTMLElement
  );
}

import { Section } from "../layout/section";
import { useTranslation } from "react-i18next";

export interface ImageProps {
  imageUrl: string;
  info?: string | string[];
  border?: boolean;
  alt?: string;
  className?: string;
  height?: string;
  scalable?: boolean;
}

export function Image({ imageUrl, alt, info, border, className, height, scalable }: ImageProps) {
  const {t} = useTranslation();
  return (
    <Section className={`${className ?? ""} img-module`}>
      <img className={`${border && "border"} ${scalable && "object-fit-scalable"}`} src={imageUrl} alt={alt} height={height}/>
      {info && (
          <div className="col-12 image-text-info">
            {Array.isArray(info)
              ? info.map((i, index) => <p key={index}>{t(i)}</p>)
              : t(info)}
          </div>
        )}
    </Section>
  );
}

import React from "react";

export interface ButtonProps {
  disabled?: boolean;
  children?: React.ReactNode;
  block?: boolean;
  right?: boolean;
}

export function Button(
  props: ButtonProps &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
) {
  const { children, block, right, disabled } = props;

  return (
    <button
      className={`${disabled ? "disabled" : ""}  button${
        block ? " btn-block" : ""
      }${right ? " button-right" : ""}`}
      {...props}
    >
      {children}
    </button>
  );
}

import { asset } from "../../util/asset";
import { ImageText } from "../module-content/image-text";
import { Paragraph } from "../module-content/paragraph";
import { useTranslation } from "react-i18next";

const INTRO_TEXT =
  "GUETESIEGEL.TEXT_1"
const IMAGE_TEXT_1 =
  "GUETESIEGEL.TEXT_2"
const IMAGE_TEXT_2 =
  "GUETESIEGEL.TEXT_3"
const IMPORTANT =
  "GUETESIEGEL.TEXT_4"

export function Guetesiegel() {
  const {t} = useTranslation()
  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT}></Paragraph>
      <ImageText
        border
        className="size-small"
        text={IMAGE_TEXT_1}
        imageUrl={asset("img/space_foundation_trademark.jpeg")}
        height="300px"
      ></ImageText>
      <ImageText
        border
        className="size-small"
        text={IMAGE_TEXT_2}
        imageUrl={asset("img/Outlast_Schulung_certificate.jpg")}
        height="300px"
      ></ImageText>
      <div className="szie-small">&nbsp;</div>
      <Paragraph className="blue">
        <p>
          <b>{t("GUETESIEGEL.TEXT_IMPORTANT")}</b> - {t(IMPORTANT)}
        </p>
        <div className="top-line"></div>
      </Paragraph>
    </>
  );
}

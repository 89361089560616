import React from "react";
import { Section } from "../layout/section";
import {useTranslation} from "react-i18next";

export interface ParagraphProps {
  text?: string;
  translationNamespace?: string;
  className?: string;
  children?: React.ReactNode;
}

export function Paragraph({ text, translationNamespace = "translations", className, children }: ParagraphProps) {
  const {t} = useTranslation([translationNamespace]);
  return (
    <Section className={className}>
      {children}
      {text && <p>{t(text, {ns: translationNamespace})}</p>}
    </Section>
  );
}

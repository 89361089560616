import { asset } from "../../util/asset";
import { Image } from "../module-content/image";
import { ImageText } from "../module-content/image-text";
import { Paragraph } from "../module-content/paragraph";
import { useTranslation } from "react-i18next";
const INTRO_TEXT =
  "ERKLAERHILFEN.TEXT_1"
const IMAGE_TEXT_1 =
  "ERKLAERHILFEN.TEXT_2"
const IMAGE_TEXT_2 =
  "ERKLAERHILFEN.TEXT_3"
const IMAGE_TEXT_3 =
  "ERKLAERHILFEN.TEXT_4"
const IMAGE_EXTRA_TEXT_1 = "ERKLAERHILFEN.TEXT_5"
const IMAGE_EXTRA_TEXT_2 = "ERKLAERHILFEN.TEXT_6"
const IMAGE_EXTRA_TEXT_3 = "ERKLAERHILFEN.TEXT_7"

export function Erklaerhilfen() {
  const {t} = useTranslation();
  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT}></Paragraph>
      <Image
        border
        className="no-padding-top"
        imageUrl={asset(
          "img/2021-09-13_Outlast_TOBIAS-FROEHNER-PHOTOGRAPHY_gespraech.jpg"
        )}
      ></Image>
      <ImageText
        border
        className="gray"
        imageUrl={asset("img/Outlast_Schulung_handschuhe.png")}
        text={IMAGE_TEXT_1}
        imageText={IMAGE_EXTRA_TEXT_1}
        height="400px"
        scalable={true}
      >
        <h4>{t("ERKLAERHILFEN.HEADLINE_1")}</h4>
        <div className="top-line"></div>
      </ImageText>
      <ImageText
        border
        imageText={IMAGE_EXTRA_TEXT_2}
        imageUrl={asset("img/Outlast_Schulung_tester.jpg")}
        text={IMAGE_TEXT_2}
      >
        <div className="bottom-line"></div>
        <div className="top-line"></div>
      </ImageText>
      <ImageText
        imageUrl={asset("img/Outlast_Schulung_wachs.jpg")}
        text={IMAGE_TEXT_3}
        className="gray"
        imageText={IMAGE_EXTRA_TEXT_3}
      ></ImageText>
    </>
  );
}

import { Section } from "./layout/section";
import { useTranslation } from "react-i18next";

export enum QuestionState {
  TODO,
  CORRECT,
  WRONG,
}

export interface ProgressBarProps {
  questionResults: QuestionState[];
  progress: number;
  correct: number;
  amount: number;
}

export function ProgressBar({
  correct,
  amount,
  progress,
  questionResults,
}: ProgressBarProps) {
  const {t} = useTranslation();
  const percentage = Math.ceil((progress / amount) * 100);

  return (
    <Section className="p0">
      <div className="progress-bar row">
        <div className="col-12 label">
          <span>
            {correct} {t("MAIN.VON")} {amount} {t("MAIN.PUNKTEN")}
          </span>
        </div>
        <div className="col-12 dots">
          <div className="dot-col done">
            {questionResults
              .filter((result) => result !== QuestionState.TODO)
              .map((result, index) => {
                return (
                  <div
                    key={index}
                    className={`dot ${
                      result === QuestionState.CORRECT ? "correct" : "wrong"
                    }`}
                  />
                );
              })}
          </div>
          <div className="dot-col todo">
            {questionResults
              .filter((result) => result === QuestionState.TODO)
              .map((_, index) => {
                return <div key={index} className={`dot`} />;
              })}
          </div>
        </div>
        <div className="col-12">
          <div className="bar">
            <div className="fill" style={{ width: `${percentage}%` }}>
              <div className="percentage">
                <span>{percentage}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

import { asset } from "../../util/asset";
import { Section } from "../layout/section";
import { ImageText } from "../module-content/image-text";
import { Paragraph } from "../module-content/paragraph";
import { useTranslation } from "react-i18next";

const INTRO_TEXT =
  "ANWENDUNGSVERFAHREN.TEXT_1"
const IMAGE_TEXT_1 =
  "ANWENDUNGSVERFAHREN.TEXT_2"
const IMAGE_TEXT_2 =
  "ANWENDUNGSVERFAHREN.TEXT_3"
const IMAGE_TEXT_3 =
  "ANWENDUNGSVERFAHREN.TEXT_4"
const IMAGE_TEXT_4 =
  "ANWENDUNGSVERFAHREN.TEXT_5"

export function Anwendungsverfahren() {
  const {t} = useTranslation();
  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT}></Paragraph>
      <Section className="size-small pt0">
        <h4>{t("ANWENDUNGSVERFAHREN.HEADLINE_1")}</h4>
      </Section>
      <ImageText
        className="pt0"
        text={IMAGE_TEXT_1}
        imageUrl={asset("img/Outlast_Schulung_micro_1.jpg")}
        textChildren={
          <div className="col-12 p0">
            <b>{t("ANWENDUNGSVERFAHREN.TEXT_6")}</b>
          </div>
        }
      ></ImageText>

      <ImageText
        className="gray"
        text={IMAGE_TEXT_2}
        imageUrl={asset("img/Outlast_Schulung_micro_2.jpg")}
        textChildren={
          <div className="col-12 p0">
            <b>{t("ANWENDUNGSVERFAHREN.TEXT_7")}</b>
          </div>
        }
      >
        <div className="top-line"></div>
        <div className="bottom-line"></div>
      </ImageText>
      <ImageText
        text={IMAGE_TEXT_3}
        imageUrl={asset("img/Outlast_Schulung_micro_3.jpg")}
        textChildren={
          <div className="col-12 p0">
            <b>{t("ANWENDUNGSVERFAHREN.TEXT_8")}</b>
          </div>
        }
      ></ImageText>
      <ImageText
        className="gray"
        text={IMAGE_TEXT_4}
        imageUrl={asset("img/Outlast_Schulung_micro_4.jpg")}
        textChildren={
          <div className="col-12 p0">
            <b>{t("ANWENDUNGSVERFAHREN.TEXT_9")}</b>
          </div>
        }
      >
        <div className="top-line"></div>
      </ImageText>
    </>
  );
}

import { useCallback, useRef } from "react";

export interface InputProps {
  type?: "text" | "email" | "password";
  label: string;
  value: string;
  onChange?: (value: string) => void;
}

export function Input({ label, value, type, onChange }: InputProps) {
  const tmp = Math.random().toString(36).substr(2);
  const name = useRef(tmp);

  const onType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div className="group">
      <input
        type={type ?? "text"}
        className={value && "not-empty"}
        onChange={onType}
        required
        name={name.current}
        value={value}
      />

      <span className="highlight"></span>

      <label htmlFor={name.current}>
        {label}
        <span className="required">*</span>
      </label>
    </div>
  );
}

import { Button } from "../components/button";
import { HoverBox } from "../components/hover-box";
import { Section } from "../components/layout/section";
import { MODULES } from "../constants/module";
import { asset } from "../util/asset";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export function MainPage() {
  const {t, i18n} = useTranslation();
  const [searchParams] = useSearchParams();
  
  const lang = searchParams.get("lang");
  
  useEffect(() => {
    if((lang !== "de") && (lang  !== "en")){
      return
    }
    if(lang)
      i18n.changeLanguage(lang);
  },[lang])

  return (
    <div>
      <Section isLarge={true} className="img">
        <img
          src={asset(
            "img/2021-09-13_Outlast_TOBIAS-FROEHNER-PHOTOGRAPHY_gespraech_2.jpg"
          )}
          alt="Outlast Hero"
        />
      </Section>
      <Section isLarge={true} className="gray">
        <h1 className="text-center" style={{ textTransform: "unset" }}>
          {t('MAIN.IQ_TITLE')}
        </h1>
        <div className="text-center">
          <p>
          {t('MAIN.IQ_TEXT')}
          </p>
        </div>
      </Section>
      <Section isLarge={true} className="modules-grid">
        <div className="row col-12">
          {MODULES.map((module) => {
            return (
              <HoverBox
                key={module.name}
                headline={`Module - ${module.name}`}
                imageSrc={module.imgSrc}
                linkText="Zum Modul"
                linkUrl={`/modul/${module.name.toLowerCase()}`}
              >
                <ul>
                  <li>
                    {module.parts.reduce(
                      (acc, cur) => cur.questions.length + acc,
                      0
                    )}{" "}
                    {t("MAIN.QUESTION")}
                  </li>
                  <li>{t("MAIN.DURATION")}: {module.estimatedTime} {t("MAIN.MINUTES")}</li>
                  <li>
                    {t("MAIN.IDEALLY")}
                  </li>
                </ul>
              </HoverBox>
            );
          })}
        </div>
      </Section>
      <Section isLarge={true} className="gray">
        <div className="top-line"></div>
        <div className="row side-by-side">
          <div className="col-lg-6">
            <img
              className="border"
              src={asset("img/trainungsunterlagen_500x400.jpeg")}
              alt="Trainingsunterlagen"
            ></img>
          </div>
          <div className="col-lg-6">
            <h3>{t("MAIN.OFFLINE_TITLE")}</h3>
            <p>
            {t("MAIN.OFFLINE_P_1")}  
            </p>
            <p>
            {t("MAIN.OFFLINE_P_2")}
            </p>
            <a href="https://www.outlast.com/kontakt">
            <Button>{t("MAIN.OFFLINE_BUTTON")}</Button>
            </a>
          </div>
        </div>
      </Section>
      <Section></Section>
    </div>
  );
}

import { Anwendungsverfahren } from "../components/content-pages/anwendungsverfahren";
import { Erklaerhilfen } from "../components/content-pages/erklaerhilfen";
import {
  Funtionsweise,
  FuntionsweiseC,
} from "../components/content-pages/funktionsweise";
import { Guetesiegel } from "../components/content-pages/guetesiegel";
import { Marke } from "../components/content-pages/marke";
import { Pflege } from "../components/content-pages/pflege";
import { Pruefmethoden } from "../components/content-pages/pruefmethoden";
import { Ursprung } from "../components/content-pages/ursprung";
import {
  VorteileA,
  VorteileB,
  VorteileC,
  VorteileD,
} from "../components/content-pages/vorteile";
import { Module, Part } from "../pages/module-page";
import { asset } from "../util/asset";

const SHARED_PARTS: Record<string, Part> = {
  ursprung: {
    name: "T_1",
    questions: [
      {
        question: "T_1_Q_1",
        answers: [
          {
            text:  "T_1_Q_1_A_1",
            resultText: "T_1_Q_1_R_1",
          },
          {
            text:  "T_1_Q_1_A_2",
            resultText: "T_1_Q_1_R_2",
          },
          {
            text:  "T_1_Q_1_A_3",
            resultText: "T_1_Q_1_R_3",
          },
        ],
        correctIndex: 1,
      },
      {
        question: "T_1_Q_2",
        answers: [
          {
            text:  "T_1_Q_2_A_1",
            resultText: "T_1_Q_2_R_1",
          },
          {
            text:  "T_1_Q_2_A_2",
            resultText: "T_1_Q_2_R_2",
          },
          {
            text:  "T_1_Q_2_A_3",
            resultText: "T_1_Q_2_R_3",
          },
        ],
        correctIndex: 1,
      },
    ],
    component: Ursprung,
  },
  guetesigel: {
    name: "T_2",
    questions: [
      {
        question:  "T_2_Q_1",
        answers: [
          {
            text:  "T_2_Q_1_A_1",
            resultText: "T_2_Q_1_R_1",
          },
          {
            text:  "T_2_Q_1_A_2",
            resultText: "T_2_Q_1_R_2",
          },
          {
            text:  "T_2_Q_1_A_3",
            resultText: "T_2_Q_1_R_3",
          },
        ],
        correctIndex: 0,
      },
      {
        question:  "T_2_Q_2",
        answers: [
          {
            text: "T_2_Q_2_A_1",
            resultText: "T_2_Q_2_R_1",
          },
          {
            text: "T_2_Q_2_A_2",
            resultText: "T_2_Q_2_R_2",
          },
          {
            text: "T_2_Q_2_A_3",
            resultText: "T_2_Q_2_R_3",
          },
        ],
        correctIndex: 1,
      },
    ],
    component: Guetesiegel,
  },
  marke: {
    name: "T_3",
    questions: [
      {
        question:"T_3_Q_1",
        answers: [
          {
            text: "T_3_Q_1_A_1",
            resultText:"T_3_Q_1_R_1",
          },
          {
            text:"T_3_Q_1_A_2",
            resultText:"T_3_Q_1_R_2",
          },
          {
            text:"T_3_Q_1_A_3",
            resultText:"T_3_Q_1_R_3",
          },
        ],
        correctIndex: 0,
      },
      {
        question:"T_3_Q_2",
        answers: [
          {
            text:"T_3_Q_2_A_1",
            resultText:"T_3_Q_2_R_1",
          },
          {
            text:"T_3_Q_2_A_2",
            resultText:"T_3_Q_2_R_2",
          },
          {
            text: "T_3_Q_2_A_3",
            resultText:"T_3_Q_2_R_3",
          },
        ],
        correctIndex: 1,
      },
    ],
    component: Marke,
  },
  pflege: {
    name: "T_4",
    questions: [
      {
        question: "T_4_Q_1",
        answers: [
          {
            text:"T_4_Q_1_A_1",
            resultText:"T_4_Q_1_R_1",
          },
          {
            text:"T_4_Q_1_A_2",
            resultText:"T_4_Q_1_R_2",
          },
          {
            text:"T_4_Q_1_A_3",
            resultText:"T_4_Q_1_R_3",
          },
        ],
        correctIndex: 2,
      },
    ],
    component: Pflege,
  },
  erklaerhilfen: {
    name: "T_5",
    questions: [
      {
        question: "T_5_Q_1",
        answers: [
          {
            text:  "T_5_Q_1_A_1",
            resultText: "T_5_Q_1_R_1",
          },
          {
            text: "T_5_Q_1_A_2",
            resultText: "T_5_Q_1_R_2",
          },
          {
            text: "T_5_Q_1_A_3",
            resultText: "T_5_Q_1_R_3",
          },
        ],
        correctIndex: 2,
      },
    ],
    component: Erklaerhilfen,
  },
  anwedungsverfahren: {
    name: "T_6",
    questions: [
      {
        question:"T_6_Q_1",
        answers: [
          {
            text: "T_6_Q_1_A_1",
            resultText:"T_6_Q_1_R_1",
          },
          {
            text:"T_6_Q_1_A_2",
            resultText:"T_6_Q_1_R_2",
          },
          {
            text:"T_6_Q_1_A_3",
            resultText:"T_6_Q_1_R_3",
          },
        ],
        correctIndex: 1,
      },
    ],
    component: Anwendungsverfahren,
  },
  pruefmethoden: {
    name: "T_7",
    questions: [
      {
        question: "T_7_Q_1",
        answers: [
          {
            text:  "T_7_Q_1_A_1",
            resultText: "T_7_Q_1_R_1",
          },
          {
            text: "T_7_Q_1_A_2",
            resultText: "T_7_Q_1_R_2",
          },
          {
            text: "T_7_Q_1_A_3",
            resultText: "T_7_Q_1_R_3",
          },
        ],
        correctIndex: 0,
      },
    ],
    component: Pruefmethoden,
  },
  funktionsweise_abd: {
    name: "T_8",
    questions: [
      {
        question: "T_8_Q_1",
        answers: [
          {
            text:  "T_8_Q_1_A_1",
            resultText: "T_8_Q_1_R_1",
          },
          {
            text: "T_8_Q_1_A_2",
            resultText: "T_8_Q_1_R_2",
          },
          {
            text: "T_8_Q_1_A_3",
            resultText: "T_8_Q_1_R_3",
          },
        ],
        correctIndex: 2,
      },
      {
        question: "T_8_Q_2",
        answers: [
          {
            text: "T_8_Q_2_A_1",
            resultText: "T_8_Q_2_R_1",
          },
          {
            text: "T_8_Q_2_A_2",
            resultText: "T_8_Q_2_R_2",
          },
          {
            text:  "T_8_Q_2_A_3",
            resultText: "T_8_Q_2_R_3",
          },
        ],
        correctIndex: 2,
      },
      {
        question:  "T_8_Q_3",
        answers: [
          {
            text: "T_8_Q_3_A_1",
            resultText: "T_8_Q_3_R_1",
          },
          {
            text: "T_8_Q_3_A_2",
            resultText: "T_8_Q_3_R_2",
          },
          {
            text: "T_8_Q_3_A_3",
            resultText: "T_8_Q_3_R_3",
          },
        ],
        correctIndex: 0,
      },
      {
        question: "T_8_Q_4",
        answers: [
          {
            text: "T_8_Q_4_A_1",
            resultText: "T_8_Q_4_R_1",
          },
          {
            text: "T_8_Q_4_A_2",
            resultText: "T_8_Q_4_R_2",
          },
          {
            text: "T_8_Q_4_A_3",
            resultText: "T_8_Q_4_R_3",
          },
        ],
        correctIndex: 1,
      },
    ],
    component: Funtionsweise,
  },
};

export const MODULES: Module[] = [
  {
    name: "Allgemein",
    imgSrc: asset("img/out_header_discover_1100x500.jpeg"),
    estimatedTime: 20,
    parts: [
      SHARED_PARTS["ursprung"],
      SHARED_PARTS["guetesigel"],
      SHARED_PARTS["marke"],
      SHARED_PARTS["funktionsweise_abd"],
      {
        name: "T_9",
        questions: [
          {
            question: "T_9_Q_1",
            answers: [
              {
                text:  "T_9_Q_1_A_1",
                resultText: "T_9_Q_1_R_1",
              },
              {
                text: "T_9_Q_1_A_2",
                resultText: "T_9_Q_1_R_2",
              },
              {
                text: "T_9_Q_1_A_3",
                resultText: "T_9_Q_1_R_3",
              },
            ],
            correctIndex: 1,
          },
        ],
        component: VorteileA,
      },
      SHARED_PARTS["pflege"],
      SHARED_PARTS["erklaerhilfen"],
      SHARED_PARTS["anwedungsverfahren"],
      SHARED_PARTS["pruefmethoden"],
    ],
  },
  {
    name: "Bekleidung",
    imgSrc: asset("img/out_header_apparel_1100x500.jpeg"),
    estimatedTime: 20,
    parts: [
      SHARED_PARTS["ursprung"],
      SHARED_PARTS["guetesigel"],
      SHARED_PARTS["marke"],
      SHARED_PARTS["funktionsweise_abd"],
      {
        name: "T_10",
        questions: [
          {
            question: "T_10_Q_1",
            answers: [
              {
                text: "T_10_Q_1_A_1",
                resultText: "T_10_Q_1_R_1",
              },
              {
                text: "T_10_Q_1_A_2",
                resultText: "T_10_Q_1_R_2",
              },
              {
                text: "T_10_Q_1_A_3",
                resultText: "T_10_Q_1_R_3",
              },
            ],
            correctIndex: 1,
          },
        ],
        component: VorteileB,
      },
      SHARED_PARTS["pflege"],
      SHARED_PARTS["erklaerhilfen"],
      SHARED_PARTS["anwedungsverfahren"],
      SHARED_PARTS["pruefmethoden"],
    ],
  },
  {
    name: "Bettwaren",
    imgSrc: asset("img/out_header_bedding_1100x500.jpeg"),
    estimatedTime: 20,
    parts: [
      SHARED_PARTS["ursprung"],
      SHARED_PARTS["guetesigel"],
      SHARED_PARTS["marke"],
      {
        name: "T_11",
        questions: [
          {
            question: "T_11_Q_1",
            answers: [
              {
                text:  "T_11_Q_1_A_1",
                resultText: "T_11_Q_1_R_1",
              },
              {
                text: "T_11_Q_1_A_2",
                resultText: "T_11_Q_1_R_2",
              },
              {
                text: "T_11_Q_1_A_3",
                resultText: "T_11_Q_1_R_3",
              },
            ],
            correctIndex: 2,
          },
          {
            question: "T_11_Q_2",
            answers: [
              {
                text: "T_11_Q_2_A_1",
                resultText: "T_11_Q_2_R_1",
              },
              {
                text: "T_11_Q_2_A_2",
                resultText: "T_11_Q_2_R_2",
              },
              {
                text:  "T_11_Q_2_A_3",
                resultText: "T_11_Q_2_R_3",
              },
            ],
            correctIndex: 2,
          },
          {
            question: "T_11_Q_3",
            answers: [
              {
                text: "T_11_Q_3_A_1",
                resultText: "T_11_Q_3_R_1",
              },
              {
                text: "T_11_Q_3_A_2",
                resultText: "T_11_Q_3_R_2",
              },
              {
                text: "T_11_Q_3_A_3",
                resultText: "T_11_Q_3_R_3",
              },
            ],
            correctIndex: 0,
          },
          {
            question: "T_11_Q_4",
            answers: [
              {
                text: "T_11_Q_4_A_1",
                resultText: "T_11_Q_4_R_1",
              },
              {
                text: "T_11_Q_4_A_2",
                resultText: "T_11_Q_4_R_2",
              },
              {
                text: "T_11_Q_4_A_3",
                resultText: "T_11_Q_4_R_3",
              },
            ],
            correctIndex: 1,
          },
        ],
        component: FuntionsweiseC,
      },
      {
        name: "T_12",
        questions: [
          {
            question: "T_12_Q_1",
            answers: [
              {
                text: "T_12_Q_1_A_1",
                resultText: "T_12_Q_1_R_1",
              },
              {
                text: "T_12_Q_1_A_2",
                resultText: "T_12_Q_1_R_2",
              },
              {
                text: "T_12_Q_1_A_3",
                resultText: "T_12_Q_1_R_3",
              },
            ],
            correctIndex: 1,
          },
        ],
        component: VorteileC,
      },
      SHARED_PARTS["pflege"],
      SHARED_PARTS["erklaerhilfen"],
      SHARED_PARTS["anwedungsverfahren"],
      SHARED_PARTS["pruefmethoden"],
    ],
  },
  {
    name: "Schuhe",
    imgSrc: asset("img/out_header_footwear_1100x500.jpeg"),
    estimatedTime: 20,
    parts: [
      SHARED_PARTS["ursprung"],
      SHARED_PARTS["guetesigel"],
      SHARED_PARTS["marke"],
      SHARED_PARTS["funktionsweise_abd"],
      {
        name: "T_13",
        questions: [
          {
            question: "T_13_Q_1",
            answers: [
              {
                text:  "T_13_Q_1_A_1",
                resultText: "T_13_Q_1_R_1",
              },
              {
                text: "T_13_Q_1_A_2",
                resultText: "T_13_Q_1_R_2",
              },
              {
                text: "T_13_Q_1_A_3",
                resultText: "T_13_Q_1_R_3",
              },
            ],
            correctIndex: 1,
          },
        ],
        component: VorteileD,
      },
      SHARED_PARTS["pflege"],
      SHARED_PARTS["erklaerhilfen"],
      SHARED_PARTS["anwedungsverfahren"],
      SHARED_PARTS["pruefmethoden"],
    ],
  },
];

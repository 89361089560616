import React, { useCallback, useContext } from "react";
import { Button } from "../components/button";
import { QuizContext } from "../components/context/quiz-context-provider";
import { Input } from "../components/input";
import { Section } from "../components/layout/section";
import { asset } from "../util/asset";
import { useTranslation } from "react-i18next";

export interface NamePageProps {
  onSubmit: () => void;
}

export function NamePage({ onSubmit }: NamePageProps) {
  const { t } = useTranslation();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    firm,
    setFirm,
    email,
    setEmail,
  } = useContext(QuizContext);

  const onFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (firstName && lastName && firm && email) {
        onSubmit();
      }
    },
    [firm, firstName, lastName, email, onSubmit]
  );

  return (
    <div>
      <Section isLarge={true} className="img">
        <img
          src={asset(
            "img/2021-09-13_Outlast_TOBIAS-FROEHNER-PHOTOGRAPHY_gespraech_2.jpg"
          )}
          alt="Outlast Hero"
        />
      </Section>
      <Section className="contact">
        <h2 className="bold">{t("MAIN.WELCOME")}</h2>
        <p style={{ marginBottom: "70px" }}>{t("MAIN.FORM_TEXT")}</p>
        <form onSubmit={onFormSubmit} className="row">
          <div className="col-12">
            <Input
              label={t("MAIN.FIRSTNAME")}
              value={firstName}
              onChange={setFirstName}
            ></Input>
          </div>
          <div className="col-12">
            <Input
              label={t("MAIN.LASTNAME")}
              value={lastName}
              onChange={setLastName}
            ></Input>
          </div>
          <div className="col-12">
            <Input
              label={t("MAIN.FIRM")}
              value={firm}
              onChange={setFirm}
            ></Input>
          </div>
          <div className="col-12">
            <Input
              type="email"
              label={t("MAIN.EMAIL")}
              value={email}
              onChange={setEmail}
            ></Input>
          </div>
          <div className="col-12">
            <Button type="submit">{t("MAIN.START_LECTURE")}</Button>
          </div>
        </form>
      </Section>
    </div>
  );
}

import React, { useRef } from "react";
import { MainPage } from "./pages/main-page";
import { Route, Routes, useLocation } from "react-router-dom";
import { MODULES } from "./constants/module";
import { ModulePage } from "./pages/module-page";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Layout } from "./components/layout/layout";
//TODO: transitions router

function App() {
  const fadeRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  return (
    <Layout>
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.pathname}
          classNames="page-fade"
          timeout={1000}
        >
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/modul">
              {MODULES.map((module) => {
                return (
                  <Route
                    key={module.name}
                    path={module.name.toLowerCase()}
                    element={<ModulePage module={module} />}
                  />
                );
              })}
            </Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  );
}

export default App;

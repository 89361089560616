import { asset } from "../../util/asset";
import { Section } from "../layout/section";
import { Image } from "../module-content/image";
import { Paragraph } from "../module-content/paragraph";
import { useTranslation } from "react-i18next";
const INTRO_TEXT = "PFLEGE.TEXT_1"

export function Pflege() {
  const {t} = useTranslation();
  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT}></Paragraph>
      <Image
        className="size-small"
        border
        imageUrl={asset("img/Outlast_Schulung_waschmaschinen.jpg")}
      ></Image>
      <Section className="size-small"></Section>
      <Paragraph className="blue">
        <div className="top-line"></div>
        <p>
          <b>{t("MAIN.SALES_HINT")}</b>
          {t("PFLEGE.TEXT_2")}
        </p>
      </Paragraph>
    </>
  );
}

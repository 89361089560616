import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DesktopNav, MobileNav, NavProps } from "./nav";

function MobileHeader({ items }: NavProps) {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = useCallback(() => {
    setNavOpen(!navOpen);
  }, [navOpen, setNavOpen]);

  useEffect(() => {
    const onResize = () => {
      setNavOpen(false);
    };
    document.body.classList.toggle("open", navOpen);
    if (navOpen) window.addEventListener("resize", onResize);
    return () => {
      document.body.classList.remove("open");
      window.removeEventListener("resize", onResize);
    };
  }, [navOpen, setNavOpen]);

  return (
    <>
      <div className="mobile">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div
                onClick={toggleNav}
                className={`hamburger ${navOpen ? "open" : ""}`}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="col-6">
              <Link to="/">
                <img
                  className="logo"
                  src={`${process.env.PUBLIC_URL}/Outlast_Logo.svg`}
                  alt="Outlast Logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <MobileNav items={items} open={navOpen} />
    </>
  );
}

export function Header({ items }: NavProps) {
  return (
    <header>
      <DesktopNav items={items} />
      <div className="placeholder"></div>
      <MobileHeader items={items} />
    </header>
  );
}

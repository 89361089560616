import { asset } from "../../util/asset";
import { Image } from "../module-content/image";
import { ImageText } from "../module-content/image-text";
import { Paragraph } from "../module-content/paragraph";
import { useTranslation} from "react-i18next";

export function Ursprung() {
  const {t} = useTranslation();
  return (
    <>
      <Paragraph className="size-small" text="URSPRUNG.INTRO_TEXT"></Paragraph>
      <ImageText
        className="size-small"
        text="URSPRUNG.IMAGE_TEXT"
        imageUrl={asset("img/Astronaut.png")}
        height="250px"
      ></ImageText>
      <Image border className="no-padding-top" imageUrl={asset("img/Astronaut_arbeit.png")}></Image>
      <Paragraph className="blue">
        <p>
          <b>{t("MAIN.SALES_HINT")}</b> {t("URSPRUNG.TIPP_TEXT")}
        </p>
        <div className="top-line"></div>
      </Paragraph>
    </>
  );
}

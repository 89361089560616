import { NAV_ITEMS, SOCIAL_MEDIA } from "../../constants/navigation";
import { Footer } from "./footer";
import { Header } from "./header";

export interface LayoutProps {
  children?: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header items={NAV_ITEMS} />
      <main>{children}</main>
      <Footer socialMedia={SOCIAL_MEDIA} />
    </>
  );
}

import { asset } from "../../util/asset";
import { Image } from "../module-content/image";
import { ImageText } from "../module-content/image-text";
import { Paragraph } from "../module-content/paragraph";

const INTRO_TEXT = "MARKE.TEXT_1"
const IMAGE_TEXT = "MARKE.TEXT_2"
export function Marke() {
  return (
    <>
      <Paragraph className="size-small" text={INTRO_TEXT}></Paragraph>
      <Image
        border
        className="no-padding-top"
        imageUrl={asset("img/outlast_house.jpg")}
      ></Image>
      <ImageText
        border
        text={IMAGE_TEXT}
        imageUrl={asset("img/Outlast_Schulung_sign.jpg")}
        className="gray"
      >
        <div className="bottom-line"></div>
        <div className="top-line"></div>
      </ImageText>
    </>
  );
}

import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Question } from "../../pages/module-page";
import { useTranslation } from "react-i18next";

export interface QuestionProps {
  question: Question;
  overrideCorrect?: boolean;
  onCorrect: () => void;
  onIncorrect: () => void;
}
//TODO: maybe move answers to a different component
export function QuestionComponent({
  question,
  overrideCorrect = false,
  onCorrect,
  onIncorrect,
}: QuestionProps) {
  const { t } = useTranslation('questions');
  const [answered, setAnswered] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [choosenIndex, setChoosenIndex] = useState<number | null>(null);

  const onAnswer = useCallback(
    (index: number, notify = true) => {
      if (answered) {
        return;
      }
      const isCorrect = index === question.correctIndex;
      setAnswered(true);
      setCorrect(index === question.correctIndex);
      setChoosenIndex(index);

      if (isCorrect && notify) {
        onCorrect();
      } else if (notify) {
        onIncorrect();
      }
    },
    [answered, onCorrect, onIncorrect, question.correctIndex]
  );

  useEffect(() => {
    if (overrideCorrect) {
      setCorrect(true);
      setChoosenIndex(question.correctIndex);
      setAnswered(true);
    }
  }, [overrideCorrect, question.correctIndex]);
  useEffect(() => {
    setCorrect(false);
    setChoosenIndex(null);
    setAnswered(false);
  }, [question]);
  return (
    <div className="question">
      <h2>{t(question.question)}</h2>
      <div className="row">
        {question.answers.map((answer, index) => {
          return (
            <div
              key={index}
              className={`col-12 answer ${
                answered && choosenIndex === index
                  ? correct
                    ? "correct"
                    : "wrong"
                  : ""
              }`}
            >
              <button onClick={() => onAnswer(index)}>{t(answer.text)}</button>
            </div>
          );
        })}
        {answered && (
          <div className="col-12">
            <div className={`result ${correct ? "correct" : "wrong"}`}>
              <p>
                <span className="border"></span>
                <span>{correct ? t("RIGHT") + ": ": t("WRONG") + ": "}</span>
                {t(question.answers[choosenIndex ?? 0]?.resultText)}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

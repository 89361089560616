import { SocialMedia } from "../components/layout/footer";
import { NavItem } from "../components/layout/nav";

export const NAV_ITEMS: NavItem[] = [
  {
    label: "NAV.MODULES",
    to: "/",
  },
  {
    label: "NAV.CONTACT",
    to: "https://www.outlast.com/kontakt",
    external: true,
  },
];
export const SOCIAL_MEDIA: SocialMedia[] = [
  {
    className: "icon-webfont_icon_instagram",
    url: "https://www.instagram.com/outlasttech/",
  },

  {
    className: "icon-webfont_icon_youtube",
    url: "https://www.youtube.com/user/OutlastTech",
  },
  {
    className: "icon-webfont_icon_facebook",
    url: "https://www.facebook.com/OutlastTech/",
  },
  {
    className: "icon-webfont_icon_linkedin",
    url: "https://www.linkedin.com/company/outlast-technologies",
  },
];

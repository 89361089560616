import { asset } from "../../util/asset";
import { Section } from "../layout/section";
import { Image } from "../module-content/image";
import { useTranslation } from "react-i18next";

export function Pruefmethoden() {
  const { t } = useTranslation();
  return (
    <>
      <Section className="size-small">
        <p>
          {t("PRUEFMETHODEN.TEXT_1")}
        </p>
      </Section>
      <Section className="p0">
        <div className="row">
          <div className="col-lg-6">
            <ul>
              <li>
                {t("PRUEFMETHODEN.TEXT_2")}
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul>
              <li>
                {t("PRUEFMETHODEN.TEXT_3")}
              </li>
            </ul>
          </div>
        </div>
      </Section>
      <Section className="size-small">
        <p>
          {t("PRUEFMETHODEN.TEXT_4")}
        </p>
      </Section>
      <Image
        border
        className="no-padding-top"
        imageUrl={asset(
          "img/2021-09-13_Outlast_TOBIAS-FROEHNER-PHOTOGRAPHY_production.jpg"
        )}
      ></Image>
    </>
  );
}

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { asset } from "../../util/asset";
import { Button } from "../button";
import { Section } from "../layout/section";
import {
  PDFDocument,
  PDFFont,
  PDFPage,
  PDFPageDrawTextOptions,
  rgb,
} from "pdf-lib";
import { QuizContext } from "../context/quiz-context-provider";
import { BASE_URL } from "../../constants/settings";
import fontkit from "@pdf-lib/fontkit";
import { useTranslation, getI18n } from "react-i18next";
import { getModuleStateKey } from "../../pages/module-page";

const FONT_COLOR = rgb(0.1137, 0.1137, 0.1059);

async function openDoc(path: string) {
  const pdfResponse = await fetch(`${BASE_URL}/${asset(path)}`);
  const buffer = await pdfResponse.arrayBuffer();
  return PDFDocument.load(buffer);
}
async function embedFont(path: string, doc: PDFDocument) {
  const fontRequest = await fetch(`${BASE_URL}/${asset(path)}`);
  const buffer = await fontRequest.arrayBuffer();

  doc.registerFontkit(fontkit);
  return doc.embedFont(buffer);
}
interface WriteTextValues {
  firstName: string;
  lastName: string;
  firm: string;
  date: string;
  resultPercentage: number;
}
function writeCenterText(
  text: string,
  page: PDFPage,
  options: PDFPageDrawTextOptions
) {
  const { width } = page.getSize();
  const { font, size } = options;

  if (!font || !size) {
    throw new Error("Font and size are required");
  }

  const textWidth = font.widthOfTextAtSize(text, size);

  page.drawText(text, {
    ...options,
    x: width / 2 - textWidth / 2,
  });
}
function writeText(
  page: PDFPage,
  font: PDFFont,
  { firstName, lastName, firm, date, resultPercentage }: WriteTextValues
) {
  const tmp_i18n = getI18n();
  const { width, height } = page.getSize();
  const bigFontSize = 27;
  const smallFontSize = 14;

  const defualtOptions = {
    color: FONT_COLOR,
    font: font,
    maxWidth: width * 0.6,
  };

  const nameText = `${firstName} ${lastName}`;
  let y = 380;
  writeCenterText(nameText, page, {
    ...defualtOptions,
    size: bigFontSize,
    y: height - y,
  });

  const firmText = `${firm}`;
  y += font.heightAtSize(bigFontSize) - 5;
  writeCenterText(firmText, page, {
    ...defualtOptions,
    size: smallFontSize,
    y: height - y,
  });
  const descLine1 = `${tmp_i18n.t(
    "CERTIFICATE.PDF.TEXT_1"
  )} ${date} ${tmp_i18n.t("CERTIFICATE.PDF.TEXT_2")}`;
  const descLine2 = `${tmp_i18n.t(
    "CERTIFICATE.PDF.TEXT_3"
  )} ${resultPercentage}% ${tmp_i18n.t("CERTIFICATE.PDF.TEXT_4")}`;
  const descLine3 = `${tmp_i18n.t("CERTIFICATE.PDF.TEXT_5")}`;
  y += 32 + font.heightAtSize(smallFontSize);
  writeCenterText(descLine1, page, {
    ...defualtOptions,
    size: smallFontSize,
    y: height - y,
  });
  y += font.heightAtSize(smallFontSize);
  writeCenterText(descLine2, page, {
    ...defualtOptions,
    size: smallFontSize,
    y: height - y,
  });
  y += font.heightAtSize(smallFontSize);
  writeCenterText(descLine3, page, {
    ...defualtOptions,
    size: smallFontSize,
    y: height - y,
  });
}
function downloadFile(bytes: Uint8Array) {
  const blob = new Blob([bytes], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "Outlast-Schulung.pdf";
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
}

export interface ModuleCertDownloadProps {
  correctQuestions: number;
  totalQuestions: number;
  moduleName: string;
}

export function ModuleCertDownload({
  moduleName,
  correctQuestions,
  totalQuestions,
}: ModuleCertDownloadProps) {
  const { t } = useTranslation();
  const tmp_i18n = getI18n();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { firstName, lastName, firm, email, mailSent, setMailSent } =
    useContext(QuizContext);
  const location = useLocation();
  const prevRoute = useRef<boolean>(true);
  useEffect(() => {
    if (correctQuestions && firm && firstName && lastName && totalQuestions) {
      // console.log("Sending the Email now");
      var data = JSON.stringify({
        lang: tmp_i18n.language,
        email: email,
        firstName: firstName,
        lastName: lastName,
        firm: firm,
        percent: Math.ceil((correctQuestions / totalQuestions) * 100),
      });
      if (mailSent !== "success") {
        fetch(asset("php/mail.php"), { method: "POST", body: data })
          .then((res) => {
            if (!res.ok) {
              throw new Error("Mail was not sent");
            }
            return res.text();
          })
          .then((txt) => {
            setMailSent(txt);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [
    correctQuestions,
    email,
    firm,
    firstName,
    lastName,
    mailSent,
    setMailSent,
    tmp_i18n.language,
    totalQuestions,
  ]);
  //terrible
  useEffect(() => {
    const modName = moduleName;
    if (prevRoute.current && process.env.NODE_ENV !== "production") {
      prevRoute.current = false;
      return;
    }
    return () => {
      localStorage.removeItem(getModuleStateKey(modName));
    };
  }, [moduleName]);

  const onDownloadClicked = useCallback(async () => {
    setLoading(true);

    const pdfDoc = await openDoc(
      asset(`pdf/template.${tmp_i18n.language}.pdf`)
    );
    const font = await embedFont(asset("pdf/font.ttf"), pdfDoc);

    const page = pdfDoc.getPage(0);

    const date = new Date().toLocaleDateString("de-DE", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    const resultPercentage = Math.ceil(
      (correctQuestions / totalQuestions) * 100
    );

    writeText(page, font, {
      firstName,
      lastName,
      firm,
      date,
      resultPercentage,
    });

    const bytes = await pdfDoc.save();
    downloadFile(bytes);

    setLoading(false);
  }, [
    correctQuestions,
    firm,
    firstName,
    lastName,
    tmp_i18n.language,
    totalQuestions,
  ]);

  return (
    <>
      <Section>
        <div className="certificate-download row">
          <div className="col-lg-6">
            <h2>
              {t("CERTIFICATE.TEXT_1")}
              {correctQuestions}
              {t("CERTIFICATE.TEXT_2")}
              {totalQuestions}
              {t("CERTIFICATE.TEXT_3")}
            </h2>
            <p>{t("CERTIFICATE.TEXT_4")}</p>
            <button className="download-link" onClick={onDownloadClicked}>
              {t("CERTIFICATE.TEXT_5")}
              <img src={asset("img/download_icon.svg")} alt="Download"></img>
            </button>
          </div>
          <div className="col-lg-6 row ">
            <div className="col-12 cert-icon">
              <img
                src={asset("img/tabelle_risiko_out_certificate_graphic.png")}
                alt="Zertifikat Icon"
              ></img>
            </div>
            <div className="col-12">
              <Button className="button col-12" onClick={() => navigate("/")}>
                {t("CERTIFICATE.BUTTON_MODULE_SELECT")}
              </Button>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
